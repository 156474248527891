import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_PP_PRODUCT_MODELS, PRODUCTS_CATALOGUE } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ProductModel, ProductsModelListResponse } from './Types';

export const ProductsListAPI = createApi({
  reducerPath: 'ProductsModelListAPI',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  tagTypes: ['ProductPlatformProductModels'],
  endpoints: (builder) => ({
    getProductsModelList: builder.query<
      ProductsModelListResponse['data']['productModel'],
      {
        printHouseUid?: string;
      }
    >({
      query: (data) => ({
        url: PRODUCTS_CATALOGUE,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: ProductsModelListResponse) => response.data.productModel,
      keepUnusedDataFor: 86400, // one day
    }),

    getPlatformProductProductModels: builder.query<
      ProductModel[],
      { printHouseUid?: string; excludeVirtualProducts?: boolean }
    >({
      query: (data) => ({
        url: GET_PP_PRODUCT_MODELS,
        method: 'POST',
        body: data,
      }),
      providesTags: () => [{ type: 'ProductPlatformProductModels' }],
      transformResponse: (response: { data: { productModel: ProductModel[] } }) => response?.data?.productModel || [],
    }),
  }),
});

export const { useGetProductsModelListQuery, useGetPlatformProductProductModelsQuery } = ProductsListAPI;
