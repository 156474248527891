import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { hotjar } from 'react-hotjar';

import { IS_LIVE, IS_STAGING } from 'config/Environment';
import {
  KEYCLOAK_ERROR_REPORT_READ_ONLY,
  KEYCLOAK_ERROR_REPORT_READ_WRITE,
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
  KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY,
  KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE,
  KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_READ,
  KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_READ,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
  KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
  KEYCLOAK_ROLE_ROUTING_READ_ALL,
} from 'config/KeycloackScopes';
import Layout from 'core/Layout';
import Loader from 'components/Loader';

import { NEWS_FEED_MESSAGE_LIST_PAGE_PATH, NEWS_FEED_PAGE_PATH } from 'containers/NewsFeed/constants';
import RoleBasedParentToChildRedirectRoute from 'core/RoleBasedParentToChildRedirectRoute';

import { HOTJAR_HJID, HOTJAR_HJSV } from './config/HotjarConfigs';
import PrintHouseManagementRoutes from './containers/PrintHouseManagement/Routes';
import NewsFeedRoutes from './containers/NewsFeed/Routes';
import { rudderInitialize } from './eventTracking';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const ProtectedRoute = lazy(() => import('./core/ProtectedRoute'));
const Login = lazy(() => import('./core/Login'));
const ProductionCapabilityByPartner = lazy(() => import('./containers/ProductionCapability/ByPartner'));
const ProductionCapabilityByUid = lazy(() => import('./containers/ProductionCapability/ByUid'));
const ProductionCapabilityByProductCategory = lazy(() => import('./containers/ProductionCapability/ByProductCategory'));
const ApparelHeatmap = lazy(() => import('./containers/ProductionCapability/containers/ApparelHeatmap'));
const AllPartners = lazy(() => import('./containers/PrintHouseManagement/AllPartners/index'));
const CreateNewPartner = lazy(
  () => import('./containers/PrintHouseManagement/AllPartners/containers/CreateNewPartner/index'),
);
const ApparelAvailability = lazy(() => import('./containers/ApparelOOS/containers/Apparel-Availability'));
const PrintPlates = lazy(() => import('./containers/PrintPlates'));
const ManagePrintJob = lazy(() => import('containers/ManagePrintJob'));
const ManagePrintJobReroutingDetails = lazy(() => import('containers/ManagePrintJob/AsyncReroute/AsyncReroutingBatch'));
const ProductionSummary = lazy(() => import('containers/Finance/ProductionSummary'));
const PricingConfiguration = lazy(() => import('containers/Finance/PricingConfiguration'));
const MachineOverview = lazy(() => import('containers/GNX/Machine'));
const OldestSupportedVersion = lazy(() => import('containers/GNX/OldestSupportedVersion'));
const MachineLogs = lazy(() => import('containers/GNX/Machine/MachineLogs'));
const PrintPartnerActivity = lazy(() => import('containers/GNX/PrintPartnerActivity'));
const StagedRollout = lazy(() => import('containers/GNX/StagedRollout'));
const SyncFeatureToggles = lazy(() => import('containers/GNX/SyncFeatureToggles'));
const ErrorManagement = lazy(() => import('containers/ErrorManagement'));
const CapacityConfiguration = lazy(() => import('containers/CapacityConfiguration'));
const OrderToDeliveryConfiguration = lazy(() => import('containers/OrderToDeliveryConfig'));
const Commands = lazy(() => import('containers/GNX/Commands'));
const CommunicationCenter = lazy(() => import('containers/CommunicationCenter'));

const App = () => {
  if (IS_LIVE || IS_STAGING) {
    rudderInitialize();
  }
  if (IS_LIVE) {
    hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
  }

  return (
    <Router>
      <Layout>
        <Suspense fallback={<Loader center />}>
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/dashboard" component={Dashboard} scopes={[]} title="Dashboard" />
            {PrintHouseManagementRoutes}
            <ProtectedRoute
              path="/production-capability/by-uid/:prodUidsFromRoute?/:phUidsFromRoute?"
              component={ProductionCapabilityByUid}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="Production Capability By Product Uid"
            />
            <ProtectedRoute
              path="/production-capability/by-partner"
              component={ProductionCapabilityByPartner}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="Production Capability By Partner"
            />
            <ProtectedRoute
              path="/production-capability/by-product-category"
              component={ProductionCapabilityByProductCategory}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="Production Capability By Product Category"
            />
            <ProtectedRoute
              path="/production-capability/apparel-heatmap"
              component={ApparelHeatmap}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
              ]}
              title="Apparel Heatmap"
            />
            <ProtectedRoute
              path="/manage-partners"
              component={AllPartners}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="Manage Partners"
            />
            <ProtectedRoute
              path="/create-partners"
              component={CreateNewPartner}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="New Partner"
            />
            <ProtectedRoute
              path="/apparel-availability"
              component={ApparelAvailability}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
              ]}
              title="Apparel Availability"
            />

            <ProtectedRoute
              path="/production-tools/print-plates"
              component={PrintPlates}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
                KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_READ,
              ]}
              title="Manage Print Plates"
            />
            <ProtectedRoute
              path="/production-tools/manage-print-job/batch"
              component={ManagePrintJobReroutingDetails}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
              ]}
              title="Manage Print Jobs: Rerouting Details"
            />
            <ProtectedRoute
              path="/production-tools/manage-print-job"
              component={ManagePrintJob}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
                KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
                KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_READ,
              ]}
              title="Manage Print Jobs"
            />
            <ProtectedRoute
              path="/production-tools/capacity-config"
              component={CapacityConfiguration}
              scopes={[KEYCLOAK_ROLE_ROUTING_READ_ALL]}
              title="Capacity Management Configuration"
            />
            <ProtectedRoute
              path="/production-tools/o2d-config"
              component={OrderToDeliveryConfiguration}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER]}
              title="Order to Delivery Configuration"
            />

            {NewsFeedRoutes}

            <ProtectedRoute
              path="/finance/production-summary"
              component={ProductionSummary}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
              ]}
              title="Production Summary"
            />
            <ProtectedRoute
              path="/finance/pricing-configuration"
              component={PricingConfiguration}
              scopes={[
                KEYCLOAK_ROLE_PRODUCTION_ADMIN,
                KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
                KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
              ]}
              title="Pricing Configuration"
            />

            <ProtectedRoute
              path="/gnx/machines/:machineId/logs"
              component={MachineLogs}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Machine logs"
            />

            <ProtectedRoute
              path="/gnx/machines"
              component={MachineOverview}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Machine overview"
            />

            <ProtectedRoute
              path="/gnx/print-partner-activity"
              component={PrintPartnerActivity}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Print partner activity"
            />
            <ProtectedRoute
              path="/gnx/staged-rollout"
              component={StagedRollout}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Staged rollout"
            />
            <ProtectedRoute
              path="/gnx/sync-feature-toggles"
              component={SyncFeatureToggles}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Sync feature toggles"
            />
            <ProtectedRoute
              path="/gnx/commands"
              component={Commands}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Commands"
            />

            <ProtectedRoute
              path="/gnx/oldest-supported-version"
              component={OldestSupportedVersion}
              scopes={[KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE]}
              title="Oldest supported version"
            />

            <ProtectedRoute
              path="/error-management"
              component={ErrorManagement}
              scopes={[KEYCLOAK_ERROR_REPORT_READ_ONLY, KEYCLOAK_ERROR_REPORT_READ_WRITE]}
              title="Error Management"
            />

            <ProtectedRoute
              path="/communication-center"
              component={CommunicationCenter}
              scopes={[KEYCLOAK_ROLE_PRODUCTION_ADMIN]}
              title="Communication Center"
            />

            <RoleBasedParentToChildRedirectRoute path={NEWS_FEED_PAGE_PATH} to={NEWS_FEED_MESSAGE_LIST_PAGE_PATH} />
            <RoleBasedParentToChildRedirectRoute path="/production-tools" to="/production-tools/manage-print-job" />
            <RoleBasedParentToChildRedirectRoute path="/production-capability" to="/production-capability/by-partner" />
            <RoleBasedParentToChildRedirectRoute path="/finance" to="/finance/production-summary" />
            <RoleBasedParentToChildRedirectRoute path="/gnx" to="/gnx/machines" />
            <Redirect key="apparel-index" path="/apparel-oos" to="/apparel-availability" />

            <Redirect key="default-index" path="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
};

export default App;
