import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { GET_WORKFLOW_TYPES, WORKFLOW_CONFIG_FOR_PH_PREFIX } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { WorkflowConfig, WorkflowCustomization, WorkflowCustomizationRequest, WorkFlowType } from './Types';

export const WorkflowConfigurationApi = createApi({
  reducerPath: 'WorkflowConfigurationApi',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getWorkflowTypes: builder.query<WorkFlowType[], null>({
      query: () => ({
        url: GET_WORKFLOW_TYPES,
        method: 'GET',
      }),
      transformResponse: (resp: { data: { integrationType?: WorkFlowType[] } }) => {
        return resp.data?.integrationType || [];
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getWorkflowConfig: builder.query<WorkflowConfig, string>({
      query: (printHouseUid) => ({
        url: `${WORKFLOW_CONFIG_FOR_PH_PREFIX}/${printHouseUid}`,
        method: 'GET',
      }),
      transformResponse: (resp: { data: WorkflowConfig }) => {
        return resp.data || { integrationTypeUid: null, customization: [] };
      },
    }),
    addWorkflowCustomization: builder.mutation<WorkflowCustomization, WorkflowCustomizationRequest>({
      query: ({ productModelUid, printHouseUid, ...otherRequestData }) => ({
        url: `${WORKFLOW_CONFIG_FOR_PH_PREFIX}/${printHouseUid}/customization/${productModelUid}`,
        method: 'POST',
        body: otherRequestData,
      }),
      transformResponse: (resp: { data: WorkflowCustomization }) => {
        return resp.data;
      },
    }),
    removeWorkflowCustomization: builder.mutation<
      WorkflowCustomization,
      { printHouseUid: string; productModelUid: string }
    >({
      query: ({ printHouseUid, productModelUid }) => ({
        url: `${WORKFLOW_CONFIG_FOR_PH_PREFIX}/${printHouseUid}/customization/${productModelUid}`,
        method: 'DELETE',
      }),
    }),
    changeDefaultWorkflow: builder.mutation<WorkflowConfig, { printHouseUid: string; integrationTypeUid: string }>({
      query: ({ printHouseUid, ...otherRequestData }) => ({
        url: `${WORKFLOW_CONFIG_FOR_PH_PREFIX}/${printHouseUid}`,
        method: 'POST',
        body: otherRequestData,
      }),
      transformResponse: (resp: { data: WorkflowConfig }) => {
        return resp.data;
      },
    }),
  }),
});

export const {
  useGetWorkflowTypesQuery,
  useGetWorkflowConfigQuery,
  useAddWorkflowCustomizationMutation,
  useRemoveWorkflowCustomizationMutation,
  useChangeDefaultWorkflowMutation,
} = WorkflowConfigurationApi;
