import {
  NEWS_FEED_BANNER_DETAILS_PAGE_PATH,
  NEWS_FEED_BANNER_LIST_PAGE_PATH,
  NEWS_FEED_BANNER_NEW_PAGE_PATH,
  NEWS_FEED_MESSAGE_DETAILS_PAGE_PATH,
  NEWS_FEED_MESSAGE_LIST_PAGE_PATH,
  NEWS_FEED_MESSAGE_NEW_PAGE_PATH,
  NEWS_FEED_PAGE_PATH,
} from 'containers/NewsFeed/constants';
import { PH_MANAGEMENT } from 'constants/index';
import {
  KEYCLOAK_ERROR_REPORT_READ_ONLY,
  KEYCLOAK_ERROR_REPORT_READ_WRITE,
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
  KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
  KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ,
  KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY,
  KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE,
  KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_READ,
  KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_READ,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN,
  KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
  KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
  KEYCLOAK_ROLE_ROUTING_READ_ALL,
  KEYCLOAK_SCOPE_MANAGE_SLA_READ,
} from 'config/KeycloackScopes';

interface MenuItem {
  name: string;
  url: string;
  icon: string;
  items: {
    name: string;
    url: string;
    subItems?: {
      name: string;
      url: string;
    }[];
    scopes?: string[];
    hideOnLive?: boolean;
  }[];
  scopes?: string[]; // parent scopes should be union of its children scopes, so that parent can be displayed for all children
  hideOnLive?: boolean;
}

export const menuItems: MenuItem[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    items: [],
    icon: 'fa-tachometer-alt',
  },
  {
    name: 'Production Tools',
    url: '/production-tools',
    icon: 'fa-tools',
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
    ],
    items: [
      {
        name: 'Manage Print Jobs',
        url: '/production-tools/manage-print-job',

        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
          KEYCLOAK_SCOPE_MANAGE_PRINT_JOBS_READ,
        ],
      },
      {
        name: 'Manage Print Plates',
        url: '/production-tools/print-plates',
        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
          KEYCLOAK_SCOPE_MANAGE_PRINT_PLATES_READ,
        ],
      },
      {
        name: 'Manage Capacity',
        url: '/production-tools/capacity-config',
        scopes: [KEYCLOAK_ROLE_ROUTING_READ_ALL],
      },
      {
        name: 'Order to Delivery Config',
        url: '/production-tools/o2d-config',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
    ],
  },
  {
    name: 'Production Capability',
    url: '/production-capability',
    icon: 'fa-warehouse',
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
    ],
    items: [
      {
        name: 'By Print Partner',
        url: '/production-capability/by-partner',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
      {
        name: 'By Product UID',
        url: '/production-capability/by-uid',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
      {
        name: 'By Product Category',
        url: '/production-capability/by-product-category',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
      {
        name: 'Apparel Heatmap',
        url: '/production-capability/apparel-heatmap',
        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
          KEYCLOAK_ROLE_CUSTOMER_SUPPORT,
        ],
      },
    ],
  },
  {
    name: 'Apparel Availability',
    url: '/apparel-availability',
    items: [],
    icon: 'fa-ghost',
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_CUSTOMER_SUPPORT_MANAGER,
    ],
  },
  {
    name: PH_MANAGEMENT,
    url: '/print-house-management',
    icon: 'fa-tasks',
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
    ],
    items: [
      {
        name: 'Address & Contacts',
        url: '/print-house-management/:printHouse/address-contact',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          { name: 'create-contact', url: '/print-house-management/:printHouse/create-contact' },
          { name: 'edit-contact', url: '/print-house-management/:printHouse/edit-contact' },
        ],
      },
      {
        name: 'FTP & Connect Config',
        url: '/print-house-management/:printHouse/ftp-connect-config',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          { name: 'ftp-config', url: '/print-house-management/:printHouse/edit-ftp-config' },
          { name: 'atp-config', url: '/print-house-management/:printHouse/edit-api-config' },
        ],
      },
      {
        name: 'Workflow Config',
        url: '/print-house-management/:printHouse/workflow-configuration',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
      {
        name: 'File Upload Scheduler',
        url: '/print-house-management/:printHouse/file-upload',
        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ,
        ],
        subItems: [
          { name: 'additional-uploads', url: '/print-house-management/:printHouse/file-upload/additional-uploads' },
        ],
      },
      {
        hideOnLive: true,
        name: 'File Upload Scheduler v2',
        url: '/print-house-management/:printHouse/file-upload-v2',
        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_SCOPE_MANAGE_FILE_UPLOAD_READ,
        ],
      },
      {
        name: 'Production SLA Config',
        url: '/print-house-management/:printHouse/sla-configuration',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER, KEYCLOAK_SCOPE_MANAGE_SLA_READ],
      },
      {
        name: 'Partner Specific Padding',
        url: '/print-house-management/:printHouse/delivery-time',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
      {
        name: 'Production Days',
        url: '/print-house-management/:printHouse/production-days',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          { name: 'add-new-production-day', url: '/print-house-management/:printHouse/add-new-production-day' },
          { name: 'edit-production-day', url: '/print-house-management/:printHouse/edit-production-day/:id' },
          { name: 'reject-production-day', url: '/print-house-management/:printHouse/reject-production-day/:id' },
        ],
      },
      {
        name: 'Capacity Management',
        url: '/print-house-management/:printHouse/capacity-management',
        scopes: [KEYCLOAK_ROLE_ROUTING_READ_ALL],
      },
      {
        name: 'Apparel Management',
        url: '/print-house-management/:printHouse/apparel-management',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          {
            name: 'setup-new-skus',
            url: '/print-house-management/:printHouse/apparel-management/setup-new-skus',
          },
          {
            name: 'manage-pricing',
            url: '/print-house-management/:printHouse/apparel-management/manage-pricing',
          },
        ],
      },
      {
        name: 'Agreement Management',
        url: '/print-house-management/:printHouse/agreement-management',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT],
      },
      {
        name: 'Stocked Items',
        url: '/print-house-management/:printHouse/stock-items',
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
      },
    ],
  },
  {
    name: 'Partner News Feed',
    url: NEWS_FEED_PAGE_PATH,
    icon: 'fa-bell',
    scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
    items: [
      {
        name: 'Messages',
        url: NEWS_FEED_MESSAGE_LIST_PAGE_PATH,
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          { name: 'message', url: `${NEWS_FEED_MESSAGE_DETAILS_PAGE_PATH}/:id` },
          { name: 'message-new', url: NEWS_FEED_MESSAGE_NEW_PAGE_PATH },
        ],
      },
      {
        name: 'Banners',
        url: NEWS_FEED_BANNER_LIST_PAGE_PATH,
        scopes: [KEYCLOAK_ROLE_PRODUCTION_ADMIN, KEYCLOAK_ROLE_PRODUCTION_DEVELOPER],
        subItems: [
          { name: 'banner', url: `${NEWS_FEED_BANNER_DETAILS_PAGE_PATH}/:id` },
          { name: 'banner-new', url: NEWS_FEED_BANNER_NEW_PAGE_PATH },
        ],
      },
    ],
  },
  {
    name: 'Finance',
    url: '/finance',
    icon: 'fa-file-invoice',
    scopes: [
      KEYCLOAK_ROLE_PRODUCTION_ADMIN,
      KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
      KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
    ],
    items: [
      {
        name: 'Production Summary',
        url: '/finance/production-summary',

        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
        ],
      },
      {
        name: 'Pricing Configuration',
        url: '/finance/pricing-configuration',
        scopes: [
          KEYCLOAK_ROLE_PRODUCTION_ADMIN,
          KEYCLOAK_ROLE_PRODUCTION_DEVELOPER,
          KEYCLOAK_ROLE_PRODUCTION_ADMIN_AGREEMENT,
        ],
      },
    ],
  },
  {
    name: 'GNX Admin tools',
    url: '/gnx',
    icon: 'fa-print',
    scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
    items: [
      {
        name: 'Machine overview',
        url: '/gnx/machines',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
      {
        name: 'Print partner activity',
        url: '/gnx/print-partner-activity',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_READ_ONLY, KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
      {
        name: 'Staged rollout',
        url: '/gnx/staged-rollout',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
      {
        name: 'Sync feature toggles',
        url: '/gnx/sync-feature-toggles',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
      {
        name: 'Commands',
        url: '/gnx/commands',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
      {
        name: 'Oldest Supported Version',
        url: '/gnx/oldest-supported-version',

        scopes: [KEYCLOAK_ROLE_MANAGE_GNX_SETTING_WRITE],
      },
    ],
  },
  {
    name: 'Error Management',
    url: '/error-management',
    items: [],
    icon: 'fa-exclamation-triangle',
    scopes: [KEYCLOAK_ERROR_REPORT_READ_ONLY, KEYCLOAK_ERROR_REPORT_READ_WRITE],
  },
  {
    name: 'Communication Center',
    url: '/communication-center',
    items: [],
    icon: 'fa-comments',
    scopes: [KEYCLOAK_ERROR_REPORT_READ_ONLY, KEYCLOAK_ERROR_REPORT_READ_WRITE],
  },
];
