import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { ERROR_MANAGEMENT_URL } from 'config/DomainsConfig';
import {
  GET_ERRORS,
  GET_ERROR_DETAILS,
  GET_ERROR_STATUSES,
  GET_FORWARD_USERS,
  GET_REASON_TREE,
  UPDATE_PRINT_HOUSE_NOTIFY_FLAG,
  UPDATE_PRODUCT_UID,
  RESEND_CHARGEBACK,
  TOGGLE_CHARGEBACK_STATUS,
} from 'config/ErrorManagementEndPoints';

import {
  CommentCreationRequestData,
  ErrorAttachment,
  ErrorComment,
  ErrorDetailInfo,
  ErrorDetailsRequest,
  ErrorStatus,
  ErrorsRequestData,
  ErrorsResponseData,
  ForwardUserType,
  ReasonItem,
  ReasonsUpdateRequestData,
  ResendChargebackRequest,
  ToggleChargebackStatusRequest,
} from './Types';

export const ErrorManagementAPI = createApi({
  reducerPath: 'ErrorManagementAPI',
  tagTypes: ['error-report', 'errors-list'],
  baseQuery: fetchBaseQueryWrapper(ERROR_MANAGEMENT_URL),
  endpoints: (builder) => ({
    getErrors: builder.query<ErrorsResponseData, ErrorsRequestData>({
      query: ({ payload, pagination }) => ({
        url: `${GET_ERRORS}?pageSize=${pagination.pageSize}&page=${pagination.page}`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (resp: { data: ErrorsResponseData }) => resp?.data,
      providesTags: ['errors-list'],
    }),
    getErrorStatuses: builder.query<ErrorStatus[], void>({
      query: () => ({
        url: GET_ERROR_STATUSES,
        method: 'GET',
      }),
      keepUnusedDataFor: 86400, // one day
      transformResponse: (resp: { data: ErrorStatus[] }) => resp?.data,
    }),
    getErrorDetails: builder.query<ErrorDetailInfo, ErrorDetailsRequest>({
      query: ({ errorId }) => ({
        url: `${GET_ERROR_DETAILS}/${errorId}`,
        method: 'GET',
      }),
      transformResponse: (resp: { data: ErrorDetailInfo }) => resp?.data,
      providesTags: (_, __, { errorId }) => [{ type: 'error-report', id: errorId }],
    }),
    getReasonsTree: builder.query<ReasonItem[], null>({
      query: () => ({
        url: GET_REASON_TREE,
        method: 'GET',
      }),
      keepUnusedDataFor: 86400, // one day
      transformResponse: (resp: { data: { reasons: ReasonItem[] } }) => resp?.data?.reasons,
    }),
    getForwardUserTypes: builder.query<ForwardUserType[], void>({
      query: () => ({
        url: GET_FORWARD_USERS,
        method: 'GET',
      }),
      keepUnusedDataFor: 86400, // one day
      transformResponse: (resp: { data: ForwardUserType[] }) => resp?.data,
    }),
    updateReasons: builder.mutation<never, ReasonsUpdateRequestData>({
      query: ({ errorId, ...payload }) => ({
        url: `${GET_ERROR_DETAILS}/${errorId}`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { errorId, ...requestData } = arg;
        const patch: Partial<ReasonsUpdateRequestData> = {
          ...requestData,
          reasonErrorId: requestData.reasonErrorId || '',
          reasonErrorTitle: requestData.reasonErrorTitle || '',
          subReasonErrorId: requestData.subReasonErrorId || [],
          subReasonErrorTitle: requestData.subReasonErrorTitle || [],
        };
        try {
          await queryFulfilled;
          // update cache on success
          dispatch(
            ErrorManagementAPI.util.updateQueryData('getErrorDetails', { errorId }, (draft) => {
              Object.assign(draft, patch);
            }),
          );
        } catch {}
      },
    }),
    createComment: builder.mutation<ErrorComment, CommentCreationRequestData>({
      query: ({ errorId, ...payload }) => ({
        url: `${GET_ERROR_DETAILS}/${errorId}/comment`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (resp: { data: ErrorComment }) => resp?.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { errorId } = arg;
        try {
          const { data: comment } = await queryFulfilled;
          const { userType } = comment;
          // update cache on success
          dispatch(
            ErrorManagementAPI.util.updateQueryData('getErrorDetails', { errorId }, (draft) => {
              Object.assign(
                draft,
                userType === 'production_user'
                  ? { internalComments: [...(draft.internalComments || []), comment] }
                  : { gnxComments: [...(draft.gnxComments || []), comment] },
              );
            }),
          );
        } catch {}
      },
    }),
    updateProdPartnerNotifyFlag: builder.mutation<
      never,
      { errorIds: string[]; notify: boolean; listingAPIArgs: ErrorsRequestData }
    >({
      query: (data) => ({
        url: UPDATE_PRINT_HOUSE_NOTIFY_FLAG,
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { errorIds, notify, listingAPIArgs } = arg;
        // update cache on success
        try {
          await queryFulfilled;
          dispatch(
            ErrorManagementAPI.util.updateQueryData('getErrors', listingAPIArgs, (draft) => {
              Object.assign(draft, {
                errors: draft.errors.map((e) => {
                  if (errorIds.includes(e.id)) {
                    return { ...e, printHouseNotify: notify };
                  }
                  return e;
                }),
              });
            }),
          );
        } catch {}
      },
    }),
    updateProductUID: builder.mutation<never, { errorIds: string[]; uid: string; listingAPIArgs: ErrorsRequestData }>({
      query: (data) => ({
        url: UPDATE_PRODUCT_UID,
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { errorIds, uid, listingAPIArgs } = arg;
        // update cache on success
        try {
          await queryFulfilled;
          dispatch(
            ErrorManagementAPI.util.updateQueryData('getErrors', listingAPIArgs, (draft) => {
              Object.assign(draft, {
                errors: draft.errors.map((e) => {
                  if (errorIds.includes(e.id)) {
                    return { ...e, productUid: uid };
                  }
                  return e;
                }),
              });
            }),
          );
        } catch {}
      },
    }),
    addImages: builder.mutation<void, { errorId: string; images: ErrorAttachment[] }>({
      query: ({ errorId, images }) => ({
        url: `${GET_ERROR_DETAILS}/${errorId}/attachment`,
        method: 'POST',
        body: { files: images.map(({ name, url }) => ({ name, url })) },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { errorId, images } = arg;
        try {
          await queryFulfilled;
          dispatch(
            ErrorManagementAPI.util.updateQueryData('getErrorDetails', { errorId }, (draft) => {
              Object.assign(draft, { attachments: [...(draft.attachments || []), ...images] }); // update cache on success
            }),
          );
        } catch {}
      },
    }),
    resendChargeback: builder.mutation<boolean, ResendChargebackRequest>({
      query: (data) => ({
        url: RESEND_CHARGEBACK,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data: boolean }) => response?.data,
      invalidatesTags: ['errors-list'],
    }),
    toggleChargebackStatus: builder.mutation<boolean, ToggleChargebackStatusRequest>({
      query: (data) => ({
        url: TOGGLE_CHARGEBACK_STATUS,
        method: 'PUT',
        body: data,
      }),
      transformResponse: (response: { data: boolean }) => response?.data,
      invalidatesTags: ['errors-list'],
    }),
  }),
});

export const {
  useGetErrorsQuery,
  useGetErrorDetailsQuery,
  useGetReasonsTreeQuery,
  useUpdateReasonsMutation,
  useCreateCommentMutation,
  useAddImagesMutation,
  useUpdateProdPartnerNotifyFlagMutation,
  useUpdateProductUIDMutation,
  useGetErrorStatusesQuery,
  useGetForwardUserTypesQuery,
  useResendChargebackMutation,
  useToggleChargebackStatusMutation,
} = ErrorManagementAPI;
