import { createSlice } from '@reduxjs/toolkit';

import { ProductsListAPI } from './ProductsListAPI';
import { ProductModel } from './Types';

const initialState = {
  productsModelList: [] as ProductModel[],
};

const productsList = createSlice({
  name: 'ProductsModelListSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ProductsListAPI.endpoints.getProductsModelList.matchFulfilled, (state, { payload }) => {
      state.productsModelList = payload;
    });
  },
});

export default productsList.reducer;
