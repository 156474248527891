import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { COMMUNICATION_CENTER_URL } from 'config/DomainsConfig';
import {
  GET_COMMUNICATIONS,
  GET_CATEGORY_TREE,
  GET_TICKET_DETAILS,
  ADD_COMMENT,
} from 'config/CommunicationEndpoints';

import {
  CommunicationCenterSearchParams,
  CommunicationCenterResponse,
  CategoryTreeResponse,
  TicketDetailsResponse,
  AddCommentRequest,
  AddCommentResponse,
} from './Types';

export const CommunicationCenterAPI = createApi({
  reducerPath: 'CommunicationCenterAPI',
  tagTypes: ['communications', 'categories', 'ticket'],
  baseQuery: fetchBaseQueryWrapper(COMMUNICATION_CENTER_URL),
  endpoints: (builder) => ({
    getCommunications: builder.query<CommunicationCenterResponse, CommunicationCenterSearchParams>({
      query: ({page, pageSize, ...params}) => ({
        url: GET_COMMUNICATIONS(params.printHouseUid),
        method: 'POST',
        body: {...params, pagination: { perPage: pageSize }},
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, params) => 
        result?.data?.tickets
          ? [
              ...result.data.tickets.map(({ id }) => ({ 
                type: 'communications' as const, 
                id 
              })),
              { type: 'communications', id: `LIST-${params.printHouseUid}` },
            ]
          : [{ type: 'communications', id: `LIST-${params.printHouseUid}` }],
    }),
    getCategoryTree: builder.query<CategoryTreeResponse, void>({
      query: () => ({
        url: GET_CATEGORY_TREE,
        method: 'GET',
      }),
      providesTags: ['categories']
    }),
    getTicketDetails: builder.query<TicketDetailsResponse, string>({
      query: (id) => ({
        url: GET_TICKET_DETAILS(id),
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'ticket', id }],
    }),
    addComment: builder.mutation<AddCommentResponse, AddCommentRequest>({
      query: ({ id, ...payload }) => ({
        url: ADD_COMMENT(id),
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ticket', id }],
    }),
  }),
});

export const {
  useGetCommunicationsQuery,
  useGetCategoryTreeQuery,
  useGetTicketDetailsQuery,
  useAddCommentMutation,
} = CommunicationCenterAPI;
