import { createSlice } from '@reduxjs/toolkit';

import { PrintHouseApi } from './PrintHouseApi';
import type { IState } from './Types';

const initialState: IState = {
  printHouses: [],
  printHouse: {
    uid: '',
    name: '',
    countryUid: '',
    currencyUid: '',
    timeZone: '',
    locale: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      postCode: '',
      city: '',
      administrativeAreaLevel1: '',
      longitude: 0,
      latitude: 0,
    },
    operationalDays: [],
    minProductionDays: 0,
    maxProductionDays: 0,
    packagerDelayTimeSec: 0,
    isEnabled: true,
    printHouseId: 0,
    printHouseUid: '',
    tenant: {
      uid: '',
      name: '',
    },
  },
  isLoading: false,
};

const printHouseSlice = createSlice({
  name: 'printHouse',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(PrintHouseApi.endpoints.getPrintHouses.matchFulfilled, (state, { payload }) => {
      state.printHouses = payload.data.printHouse;
      state.isLoading = false;
    });
    builder.addMatcher(PrintHouseApi.endpoints.getPrintHouse.matchFulfilled, (state, { payload }) => {
      state.printHouse = payload;
      state.isLoading = false;
    });
  },
});

export default printHouseSlice.reducer;
