import { createApi } from '@reduxjs/toolkit/query/react';

import { GN_PROXY_TO_IDENTITY_SERVICE_URL } from 'config/DomainsConfig';
import {
  CREATE_USER,
  UPDATE_DELETE_USER_PREFIX,
  SEARCH_CLIENTS,
  SEARCH_USERS,
  SEARCH_USER_METADATA,
  USER_METADATA_PREFIX,
  SEARCH_TENANTS,
} from 'config/IdentityServiceEndpoints';
import { GELATO_CLIENT_ID } from 'config/ClientId';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ISelectOption } from 'components/SimpleSelect/Types';

import {
  IdentityClient,
  IdentityContact,
  IdentityContactCreationRequest,
  IdentityContactUpdateRequest,
  IdentityTenant,
  IdentityUser,
  IdentityUserWithPermissions,
  UserMetadata,
} from './Types';

export const printHouseIdentityContactApi = createApi({
  reducerPath: 'printHouseIdentityContactApi',
  tagTypes: ['identityContacts'],
  baseQuery: fetchBaseQueryWrapper(GN_PROXY_TO_IDENTITY_SERVICE_URL),
  endpoints: (builder) => ({
    getPrintHouseClientId: builder.query<{ uid: string; uuid: string }, string>({
      query: (uid) => ({
        url: SEARCH_CLIENTS,
        method: 'POST',
        body: { name: uid, types: ['production-partner'] },
      }),
      transformResponse: (resp: { data: { id: string; name: string }[] }, _, uid) => {
        let uuid = '';
        const client = resp?.data?.find(({ name }) => name === uid);
        if (client) {
          uuid = client.id;
        }
        return { uid, uuid };
      },
    }),
    getPrintHouseContacts: builder.query<IdentityContact[], string>({
      query: (clientId) => ({
        url: SEARCH_USERS,
        method: 'POST',
        body: {
          clientId,
          statuses: ['', 'active'],
          limit: 100,
          offset: 0,
          metadata: [{ key: 'isContact', value: 'true' }],
        },
      }),
      transformResponse: (resp: { data: IdentityContact[] }) => {
        return resp.data || [];
      },
      providesTags: ['identityContacts'],
    }),
    getUserByEmail: builder.query<IdentityContact, string>({
      query: (userEmail) => ({
        url: SEARCH_USERS,
        method: 'POST',
        body: {
          email: userEmail,
          limit: 1,
          offset: 0,
        },
      }),
      transformResponse: (resp: { data: IdentityContact[] }, _, userEmail) => {
        return resp.data?.find(({ email }) => email === userEmail); // need to add additional check  ref: https://gelato.atlassian.net/browse/GPS-2470
      },
      providesTags: ['identityContacts'],
    }),
    getContactsMetadata: builder.query<UserMetadata[], string[]>({
      query: (userIds) => ({
        url: SEARCH_USER_METADATA,
        method: 'POST',
        body: { userIds, limit: 100, offset: 0 },
      }),
      transformResponse: (resp: { metadata: UserMetadata[] }) => {
        return resp.metadata || [];
      },
    }),
    createContact: builder.mutation<IdentityContact, IdentityContactCreationRequest>({
      query: (data) => ({
        url: CREATE_USER,
        method: 'POST',
        body: { status: '', ...data },
      }),
    }),
    deleteContact: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `${UPDATE_DELETE_USER_PREFIX}/${id}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['identityContacts'],
    }),
    updateContact: builder.mutation<IdentityContact, IdentityContactUpdateRequest>({
      query: ({ id, ...data }) => ({
        url: `${UPDATE_DELETE_USER_PREFIX}/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    setContactMetadata: builder.mutation<unknown, { contactId: string; metadata: UserMetadata[] }>({
      query: (data) => ({
        url: `${USER_METADATA_PREFIX}/${data.contactId}/metadata`,
        method: 'PUT',
        body: {
          metadata: data.metadata,
        },
      }),
    }),
    deleteContactMetadata: builder.mutation<unknown, string>({
      query: (contactId) => ({
        url: `${USER_METADATA_PREFIX}/${contactId}/metadata`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['identityContacts'],
    }),
    getContactDataWithPermissionsById: builder.query<IdentityUserWithPermissions[], string[]>({
      query: (ids) => ({
        url: SEARCH_USERS,
        method: 'POST',
        body: { ids, limit: 100, offset: 0, returnPermissions: true }, // max limit is 100
      }),
      transformResponse: (resp: { data: IdentityUserWithPermissions[] }) => {
        return resp.data || [];
      },
    }),
    getpartnerManagersByIds: builder.query<IdentityUser[], string[]>({
      query: (ids) => ({
        url: SEARCH_USERS,
        method: 'POST',
        body: { ids, limit: 100, offset: 0, clientId: GELATO_CLIENT_ID }, // max limit is 100
      }),
      transformResponse: (resp: { data: IdentityUser[] }) => {
        return resp.data || [];
      },
    }),
    getPartnerManagersByName: builder.query<IdentityUser[], string>({
      query: (name) => ({
        url: SEARCH_USERS,
        method: 'POST',
        body: { firstName: name, limit: 20, offset: 0, clientId: GELATO_CLIENT_ID },
      }),
      transformResponse: (resp: { data: IdentityUser[] }) => {
        return resp.data || [];
      },
    }),
    getClients: builder.query<IdentityClient[], string>({
      query: (name) => ({
        url: SEARCH_CLIENTS,
        method: 'POST',
        body: { name },
      }),
      transformResponse: (resp: { data: IdentityClient[] }) => {
        return resp.data || [];
      },
    }),
    getTenantsOptions: builder.query<ISelectOption[], void>({
      query: () => ({
        url: SEARCH_TENANTS,
        method: 'POST',
        body: {
          limit: 500,
          offset: 0,
        },
      }),
      transformResponse: (resp: { tenants: IdentityTenant[] }) => {
        if (resp.tenants) {
          return resp.tenants.map((tenant) => ({ value: tenant.id, label: tenant.name }));
        }
        return [];
      },
    }),
  }),
});

export const {
  useGetPrintHouseClientIdQuery,
  useGetPrintHouseContactsQuery,
  useLazyGetUserByEmailQuery,
  useGetContactsMetadataQuery,
  useLazyGetContactsMetadataQuery,
  useCreateContactMutation,
  useSetContactMetadataMutation,
  useDeleteContactMutation,
  useGetContactDataWithPermissionsByIdQuery,
  useLazyGetContactDataWithPermissionsByIdQuery,
  useDeleteContactMetadataMutation,
  useUpdateContactMutation,
  useGetpartnerManagersByIdsQuery,
  useLazyGetpartnerManagersByIdsQuery,
  useLazyGetPartnerManagersByNameQuery,
  useLazyGetClientsQuery,
  useGetTenantsOptionsQuery,
} = printHouseIdentityContactApi;
