export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL as string;
export const PRODUCTION_UI_BACKEND = process.env.REACT_APP_PRODUCTION_UI_BACKEND as string;
export const GELATO_NETWORK_URL = process.env.REACT_APP_GELATO_NETWORK_URL as string;
export const IMPOSITION_URL = process.env.REACT_APP_IMPOSITION_URL as string;
export const REACT_APP_GNX_URL = process.env.REACT_APP_GNX_URL as string;
export const MACHINE_PARK_URL = process.env.REACT_APP_MACHINE_PARK_URL as string;
export const CONNECT_API_URL = process.env.REACT_APP_CONNECT_API_URL as string;
export const SHIPMENT_API_URL = process.env.REACT_APP_SHIPMENT_API_URL as string;
export const APPAREL_MANAGEMENT_URL = process.env.REACT_APP_SKU_PRODUCT_MAPPING_API_URL as string;
export const AGREEMENT_MANAGEMENT_URL = process.env.REACT_APP_AGREEMENT_SERVICE_API_URL as string;
export const PRODUCTION_SUMMARY_SERVICE_URL = `${PRODUCTION_UI_BACKEND}/api/pss`;
export const GN_PROXY_TO_IDENTITY_SERVICE_URL = `${GELATO_NETWORK_URL}/service/api/proxy/identity`;
export const ERROR_MANAGEMENT_URL = `${PRODUCTION_UI_BACKEND}/api/error-report`;
export const GELATO_NETWORK_PROXY_URL = `${PRODUCTION_UI_BACKEND}/api/gn`; // to avoid cors on old APIs
export const GN_TRANSLATIONS_URL = process.env.REACT_APP_GN_TRANSLATIONS_URL as string;
export const STOCK_SERVICE_URL = `${PRODUCTION_UI_BACKEND}/api/stock`;
export const COMMUNICATION_CENTER_URL = `${PRODUCTION_UI_BACKEND}/api/error-report/cm`;

export const GNX_APP_ALPHA_URL = process.env.REACT_APP_GNX_APP_URL_ALPHA_URL as string;
export const GNX_APP_BETA_URL = process.env.REACT_APP_GNX_APP_URL_BETA_URL as string;
export const GNX_APP_URL = process.env.REACT_APP_GNX_APP_URL as string;
export const LOGISTICS_UI = process.env.REACT_APP_LOGISTICS_UI as string;
export const API_DASHBOARD = process.env.REACT_APP_API_DASHBOARD as string;

export const ROUTING_URL = process.env.REACT_APP_ROUTING_API_URL as string;
export const LOGISTIC_COST_URL = process.env.REACT_APP_LOGISTIC_COST_API_URL as string;

export const FILE_UPLOADER_COMPANION_URL = process.env.REACT_APP_FILE_UPLOADER_COMPANION_URL as string;
